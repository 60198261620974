import React, { useEffect, useState } from "react"
import { FaSearch, FaTimes } from "react-icons/fa"
import ContentCard from "../../../components/content/contentCard"
import ContentSkeleton from "../../../components/ContentSkeleton"
import BackButton from "../../../components/buttons/BackButton"
import ContentPageLayout from "../../../components/ContentPageLayout"
import config from "../../../config"
import Seo from "../../../components/seo"
import firebase from "firebase"
import { firebaseInit } from "../../../config.js"

const CoachBlockPage = ({ params }) => {
  const [contentLoading, setContentLoading] = useState(true)
  const [filteredCards, setFilteredCards] = useState([])
  const [searchVisible, setSearchVisible] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [noResults, setNoResults] = useState(false)
  const [coachBanner, setCoachBanner] = useState(null)
  const [imageHeight, setImageHeight] = useState("auto")
  const [userHasSuscription, setUserHasSuscription] = useState(null)
  const [blockTitle, setBlockTitle] = useState(null)

  const profileUri = config.serverUri
  const blockId = params.block
  const coachId = params.id

  // Inicializar autenticación y obtener información de suscripción del usuario
  useEffect(() => {
    firebaseInit(firebase)
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        fetch(`${profileUri}/users/?email=${user.email}`)
          .then(response => {
            if (response.ok) {
              response.json().then(data => {
                if (data[0] && data[0].suscription)
                  setUserHasSuscription(data[0].suscription)
              })
            }
          })
          .catch(error => console.log(error))
      } else {
        console.log("user is null")
        setUserHasSuscription(false)
      }
    })
  }, [profileUri])

  // Carga de datos: se obtiene la información del bloque a través del nuevo endpoint y el banner de coaches
  useEffect(() => {
    const fetchBlockData = async () => {
      try {
        setContentLoading(true)
        // Usamos el nuevo endpoint para obtener el contenido del coach filtrado por bloque
        const blockResponse = await fetch(
          `${config.serverUri}/coaches/content-coach/${coachId}?block=${blockId}`
        )
        const blockData = await blockResponse.json()
        // Si se envía el parámetro block, se espera que blockData.blocks sea un objeto que contenga la propiedad "cards"
        if (blockId) {
          if (blockData.blocks) {
            setFilteredCards(
              blockData.blocks.cards ? blockData.blocks.cards : []
            )
            setBlockTitle(
              blockData.blocks.titulo ? blockData.blocks.titulo : ""
            )
          } else {
            setFilteredCards([])
            setBlockTitle("")
          }
        } else {
          // Sin parámetro block, combinamos las tarjetas de todos los bloques
          if (blockData.blocks && Array.isArray(blockData.blocks)) {
            const allCards = blockData.blocks.reduce((cards, block) => {
              return cards.concat(block.cards || [])
            }, [])
            setFilteredCards(allCards)
          } else {
            setFilteredCards([])
          }
        }

        // Establecer si no se encontraron resultados
        const totalCards =
          blockId && blockData.blocks && blockData.blocks.cards
            ? blockData.blocks.cards.length
            : blockData.blocks && Array.isArray(blockData.blocks)
            ? blockData.blocks.reduce(
                (sum, block) => sum + (block.cards ? block.cards.length : 0),
                0
              )
            : 0
        setNoResults(totalCards === 0)
        console.log(blockData)

        // Obtener banner de coaches
        const bannerResponse = await fetch(
          `${config.serverUri}/banner-de-coaches`
        )
        const bannersData = await bannerResponse.json()
        const banner = bannersData[0]
        setCoachBanner(banner || null)

        // Calcular la altura de la imagen del banner según su aspect ratio
        if (banner?.desktopImage?.width && banner?.desktopImage?.height) {
          const aspectRatio =
            banner.desktopImage.height / banner.desktopImage.width
          setImageHeight(window.innerWidth * aspectRatio)
        }
      } catch (error) {
        console.error("Error fetching block or banner data:", error)
      } finally {
        setContentLoading(false)
      }
    }

    fetchBlockData()
  }, [blockId, coachId])

  const toggleSearch = () => {
    setSearchVisible(!searchVisible)
    setSearchText("")
    setNoResults(false)
  }

  const handleSearch = () => {
    if (!searchText.trim()) {
      setNoResults(false)
      return
    }
    const filtered = filteredCards.filter(card =>
      card.titulo.toLowerCase().includes(searchText.toLowerCase())
    )
    setFilteredCards(filtered)
    setNoResults(filtered.length === 0)
  }

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      handleSearch()
    }
  }

  const getImageUrl = url =>
    url?.startsWith("http") ? url : `${config.serverUri}${url}`

  return (
    <ContentPageLayout>
      <Seo title="Coach Block" />
      {contentLoading ? (
        <ContentSkeleton />
      ) : (
        <div>
          {/* Header */}
          {coachBanner && (
            <header>
              <div>
                <div className="flex p-4">
                  <div className="flex justify-center">
                    <BackButton fontSize={30} route={`/coaches/${coachId}`} />
                    {blockId && blockTitle && (
                      <span className="text-left font-bold text-xl ml-4 ">
                        {blockTitle}
                      </span>
                    )}
                  </div>
                  {/* Botón e input de búsqueda */}

                  <div className="ml-auto flex items-center justify-end">
                    {!searchVisible ? (
                      <FaSearch
                        fontSize={18}
                        className="cursor-pointer"
                        onClick={() => setSearchVisible(true)}
                      />
                    ) : (
                      <>
                        <input
                          type="text"
                          value={searchText}
                          onChange={e => setSearchText(e.target.value)}
                          onKeyDown={handleKeyDown}
                          placeholder="Buscar..."
                          className="bg-transparent border-b focus:outline-none pr-4"
                        />
                        <FaTimes
                          fontSize={18}
                          className="cursor-pointer ml-2"
                          onClick={toggleSearch}
                        />
                        <FaSearch
                          fontSize={18}
                          className="cursor-pointer ml-2"
                          onClick={handleSearch}
                        />
                      </>
                    )}
                  </div>
                </div>

                {/* Banner */}
                <div
                  className="relative bg-white"
                  style={{ width: "100%", height: `${imageHeight}px` }}
                >
                  <img
                    className="absolute"
                    src={getImageUrl(coachBanner.desktopImage.url)}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    alt="Coach Banner"
                  />
                </div>
              </div>
            </header>
          )}

          {/* Renderizado de tarjetas */}
          <div className="flex flex-wrap justify-center my-8">
            {filteredCards.length > 0 ? (
              filteredCards.map((card, key) => (
                <div className="sm:full p-2" key={key}>
                  <ContentCard
                    card={card}
                    userHasSuscription={userHasSuscription}
                  />
                </div>
              ))
            ) : (
              <p>
                {noResults
                  ? "No hay elementos que coincidan con la búsqueda."
                  : "No hay tarjetas disponibles."}
              </p>
            )}
          </div>
        </div>
      )}
    </ContentPageLayout>
  )
}

export default CoachBlockPage
